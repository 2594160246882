/**
 * Polling beenden
 * @param intervalId 
 * @param performPolling 
 */
const abortPolling = (intervalId: number, performPolling: () => Promise<void>) => {
    clearInterval(intervalId);
    window.removeEventListener("focus", performPolling);
};

/**
 * Intervall: Abbruch durch abgelaufene Zeit
 * @param requestFunction Auszuführende Funktion. Wenn true, wird das Polling abgebrochen
 * @param maxTime Standard 3 Minuten
 * @param interval Standard 3 Sekunden
 * @param endFunction Auszuführende Funktion, wenn maxLoops Durchläufe erreicht wurden
 */
const startPollingIntervalTime = async (requestFunction: () => Promise<boolean> | boolean, maxTime: number = 60 * 3 * 1000, interval: number = 3 * 1000, endFunction: () => void = () => {}) => {
    const performPolling = async () => {
        if (document.hasFocus()) {
            const shouldAbort = await requestFunction();

            if (shouldAbort) {
                abortPolling(intervalId, performPolling);
                return;
            }
        }
    };

    if (await requestFunction()) {
        return;
    }

    const intervalId = window.setInterval(async () => {
        await performPolling();
    }, interval);
    
    setTimeout(() => {
        abortPolling(intervalId, performPolling);
        endFunction();
    }, maxTime);

    window.addEventListener("focus", performPolling);
};

/**
 * Intervall: Abbruch durch maximale Anzahl Durchläufe
 * @param requestFunction Auszuführende Funktion. Wenn true, wird das Polling abgebrochen
 * @param maxLoops Standard 60 Durchläufe = 3 Minuten
 * @param delay Standard 3 Sekunden
 * @param errorFunction Auszuführende Funktion, wenn maxLoops Durchläufe erreicht wurden
 */
const startPollingIntervalLoops = async (requestFunction: () => Promise<boolean> | boolean, maxLoops: number = 60, delay: number = 3 * 1000, errorFunction: () => void = () => {}) => {
    const performPolling = async () => {
        if (document.hasFocus()) {
            const shouldAbort = await requestFunction();

            if (shouldAbort) {
                abortPolling(intervalId, performPolling);
                return;
            }
            if (++i == maxLoops) {
                abortPolling(intervalId, performPolling);
                errorFunction();
                return;
            }
        }
    };

    let i = 0;

    if (await requestFunction()) {
        return;
    }

    const intervalId = window.setInterval(async () => {
        await performPolling();
    }, delay);

    window.addEventListener("focus", performPolling);
};

export {startPollingIntervalLoops, startPollingIntervalTime}

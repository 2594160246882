import { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useAxiosAuthenticated from "../hooks/useAxiosAuthenticated";
import {
    VIEW_PERMISSION_ADMIN_MANAGE_VEHICLE_DATA,
    VIEW_PERMISSION_CHARGING_STATIONS_WITH_MAP,
    VIEW_PERMISSION_EXPORTS,
    VIEW_PERMISSION_IDENTIFICATION_MEDIA,
    VIEW_PERMISSION_IDENTIFICATION_MEDIA_DATA_AVAILABLE,
    VIEW_PERMISSION_INFRASTRUCTURE_CHARGEPOINT_DATA_AVAILABLE,
    VIEW_PERMISSION_INFRASTRUCTURE_LOCATIONS_DATA_AVAILABLE,
    VIEW_PERMISSION_INFRASTRUCTURE_STATISTICS_DATA_AVAILABLE,
    VIEW_PERMISSION_LOCATION_ACCESS,
    VIEW_PERMISSION_LOCATION_TECHNICAL_DATA,
    VIEW_PERMISSION_MANAGE_GROUPS,
    VIEW_PERMISSION_MANAGE_LOGINS,
    VIEW_PERMISSION_MANAGE_VEHICLE_INFO,
    VIEW_PERMISSION_MANAGE_VIEW_GROUPS,
    VIEW_PERMISSION_OCPP_SETTINGS_READ,
    VIEW_PERMISSION_OCPP_SETTINGS_EDIT,
    VIEW_PERMISSION_PLAUSI_CHECK_CHARGELOGS, VIEW_PERMISSION_PHONE_CALL,
} from "../models/ViewPermissionsModel";
import AdminViewGroupEditScreen from "../screens/admin/AdminViewGroupEditScreen";
import PersistLogin from "./PersistLogin";
import ProtectedRoute from "./ProtectedRoute";
import LayoutComponent from "./components/LayoutComponent";
import LayoutLoggedOutComponent from "./components/LayoutLoggedOutComponent";
import { startPollingIntervalTime } from "./helpers/PollingHelper";
import { ONE_MINUTE, ONE_DAY } from "./helpers/DateAndTimeHelper";

const IdentificationMediaScreen = lazy(() => import("../screens/IdentificationMediaScreen"));
const IdentificationMediaDetailsScreen = lazy(() => import("../screens/identification-media/DetailsScreen"));
const AdminGroupEditScreen = lazy(() => import("../screens/admin/AdminGroupEditScreen"));
const AdminGroupOverviewScreen = lazy(() => import("../screens/admin/AdminGroupOverviewScreen"));
const AdminViewGroupOverviewScreen = lazy(() => import("../screens/admin/AdminViewGroupOverviewScreen"));
const UserLoginScreen = lazy(() => import("../screens/user/UserLoginScreen"));
const HomeScreen = lazy(() => import("../screens/HomeScreen"));
const InfrastructureLocationsScreen = lazy(() => import("../screens/infrastructure/InfrastructureLocationsScreen"));
const InfrastructureChargingStationsScreen = lazy(() => import("../screens/infrastructure/InfrastructureChargingStationsScreen"));
const InfrastructureChargingStationsDetailScreen = lazy(
    () => import("../screens/infrastructure/charging-station/ChargingStationDetailScreen")
);
const ChargelogsScreen = lazy(() => import("../screens/ChargelogsScreen"));
const UserLogoutScreen = lazy(() => import("../screens/user/UserLogoutScreen"));
const UserSettingsScreen = lazy(() => import("../screens/user/UserSettingsScreen"));
const UserDataExportsScreen = lazy(() => import("../screens/user/UserDataExportsScreen"));
const UserDataExportsDownloadScreen = lazy(() => import("../screens/user/UserDataExportsDownloadScreen"));
const InfrastructureLocationDetailsScreen = lazy(() => import("../screens/infrastructure/InfrastructureLocationDetailScreen"));
const InfrastructureStatisticsScreen = lazy(() => import("../screens/infrastructure/InfrastructureStatisticsScreen"));
const PageNotFoundScreen = lazy(() => import("../screens/PageNotFoundScreen"));
const PageForbiddenScreen = lazy(() => import("../screens/PageForbiddenScreen"));
const AdminUserOverviewScreen = lazy(() => import("../screens/admin/AdminUserOverviewScreen"));
const AdminLoginEditScreen = lazy(() => import("../screens/admin/AdminLoginEditScreen"));
const UserForgotPasswordEmailScreen = lazy(() => import("../screens/user/UserForgotPasswordEmailScreen"));
const UserResetPasswordScreen = lazy(() => import("../screens/user/UserResetPasswordScreen"));
const AdminPlausiChecksChargelogsOverviewScreen = lazy(() => import("../screens/admin/AdminPlausiChecksChargelogsOverviewScreen"));
const AdminPlausiChecksChargelogsDetailScreen = lazy(() => import("../screens/admin/AdminPlausiChecksChargelogsDetailScreen"));
const AdminManageVehicleData = lazy(() => import("../screens/admin/AdminManageVehicleData"));
const AdminManageVehicleDataDetails = lazy(() => import("../screens/admin/AdminManageVehicleDataDetails"));
const AdminOcppTemplateScreen = lazy(() => import("../screens/admin/AdminOcppTemplateScreen"));
const NotificationCenterScreen = lazy(() => import("../screens/NotificationCenterScreen"));
const AdminOcppTemplateDetailsScreen = lazy(() => import("../screens/admin/AdminOcppTemplateDetailsScreen"))
const AdminPhoneCallScreen = lazy(() => import("../screens/admin/AdminPhoneCallScreen"))
const AdminPhoneCallEditScreen = lazy(() => import("../screens/admin/AdminPhoneCallEditScreen"))

function RoutesConfig() {
    const { i18n, t } = useTranslation();
    const { user, isDone, setUser, setPermissions, setIsDone, setUnreadNotificationsAmount } = useAuth();
    const [forceUpdate, setForceUpdate] = useState<number>(0);
    const axiosAuthenticated = useAxiosAuthenticated();

    useEffect(() => {
        document.title = t("meta_title_cmp");
    }, [i18n.language]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getData = async () => {
            try {
                const response = await axiosAuthenticated.get("/user/info", {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setUnreadNotificationsAmount(response.data.data.user.unread_notifications_amount);
                    setUser(response.data.data.user);
                    setPermissions(response.data.data.user.permissions);
                    await i18n.changeLanguage(response.data.data.user.language);
                    setIsDone(true);
                }
            } catch (e) {
                setIsDone(true);
            }
        };

        void getData();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosAuthenticated, forceUpdate]);

    const doForceUpdate = async () => {
        setForceUpdate((c) => c + 1);

        // Wenn Benutzer sich auslogt, Polling abbrechen
        if (user === null) {
            return true;
        }
        return false;
    };

    const pollingFinished = () => {
        window.location.reload();
    };

    useEffect(() => {
        const startPolling = async () => {
            await startPollingIntervalTime(doForceUpdate, ONE_DAY, ONE_MINUTE, pollingFinished);
        };

        if (isDone) {
            window.setTimeout(() => {
                startPolling();
            }, ONE_MINUTE);
        }
    }, [isDone]);

    const getLoginUrl = () => {
        return "/user/login";
    };

    return (
        <BrowserRouter>
            <Suspense fallback={null}>
                <Routes>
                    <Route element={<LayoutComponent />}>
                        {user && <Route path={getLoginUrl()} element={<Navigate to={"/"} />} />}
                        <Route element={<PersistLogin />}>
                            <Route element={<ProtectedRoute />}>
                                <Route path="/" element={<HomeScreen />} />
                            </Route>

                            <Route
                                element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_INFRASTRUCTURE_STATISTICS_DATA_AVAILABLE} />}
                            >
                                <Route path={"/infrastruktur/statistiken"} element={<InfrastructureStatisticsScreen />} />
                            </Route>

                            <Route
                                element={
                                    <ProtectedRoute
                                        requireAllPermissions={[
                                            VIEW_PERMISSION_LOCATION_ACCESS,
                                            VIEW_PERMISSION_INFRASTRUCTURE_LOCATIONS_DATA_AVAILABLE,
                                        ]}
                                    />
                                }
                            >
                                <Route path={"/infrastruktur/standorte"} element={<InfrastructureLocationsScreen />} />
                                <Route path={"/infrastruktur/standorte/:locationId"} element={<InfrastructureLocationDetailsScreen />} />
                                <Route
                                    path={"/infrastruktur/standorte/:locationId/uebersicht"}
                                    element={<InfrastructureLocationDetailsScreen tabNumber={0} />}
                                />
                                <Route
                                    path={"/infrastruktur/standorte/:locationId/ladestationen"}
                                    element={<InfrastructureLocationDetailsScreen tabNumber={1} />}
                                />
                                <Route
                                    path={"/infrastruktur/standorte/:locationId/ladevorgaenge"}
                                    element={<InfrastructureLocationDetailsScreen tabNumber={2} />}
                                />
                                <Route
                                    path={"/infrastruktur/standorte/:locationId/einkaufspreise-verwalten"}
                                    element={<InfrastructureLocationDetailsScreen tabNumber={3} />}
                                />
                                <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_LOCATION_TECHNICAL_DATA} />}>
                                    <Route
                                        path={"/infrastruktur/standorte/:locationId/technische-angaben"}
                                        element={<InfrastructureLocationDetailsScreen tabNumber={4} />}
                                    />
                                </Route>
                            </Route>

                            <Route
                                element={
                                    <ProtectedRoute
                                        requireAllPermissions={[
                                            VIEW_PERMISSION_CHARGING_STATIONS_WITH_MAP,
                                            VIEW_PERMISSION_INFRASTRUCTURE_CHARGEPOINT_DATA_AVAILABLE,
                                        ]}
                                    />
                                }
                            >
                                <Route path={"/infrastruktur/ladestationen"} element={<InfrastructureChargingStationsScreen />} />
                                <Route
                                    path={"/infrastruktur/ladestationen/:chargingStationId/ladevorgaenge"}
                                    element={<InfrastructureChargingStationsDetailScreen tabNumber={0} />}
                                />
                                <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_OCPP_SETTINGS_READ} />}>
                                {/* TODO: hier die neue Berechtigung verwenden */}
                                    <Route
                                        path={"/infrastruktur/ladestationen/:chargingStationId/konfiguration"}
                                        element={<InfrastructureChargingStationsDetailScreen tabNumber={1} />}
                                    />
                                </Route>
                            </Route>

                            <Route element={<ProtectedRoute />}>
                                <Route path={"/ladevorgaenge"} element={<ChargelogsScreen />} />
                            </Route>

                            <Route
                                element={
                                    <ProtectedRoute
                                        requireAllPermissions={[
                                            VIEW_PERMISSION_IDENTIFICATION_MEDIA,
                                            VIEW_PERMISSION_IDENTIFICATION_MEDIA_DATA_AVAILABLE,
                                        ]}
                                    />
                                }
                            >
                                <Route path={"/lademedien"} element={<IdentificationMediaScreen />} />
                                <Route
                                    path={"/lademedien/:identificationMediaId/ladevorgaenge"}
                                    element={<IdentificationMediaDetailsScreen tabNumber={0} />}
                                />
                                <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_MANAGE_VEHICLE_INFO} />}>
                                    <Route
                                        path={"/lademedien/:identificationMediaId/fahrzeuginfo"}
                                        element={<IdentificationMediaDetailsScreen tabNumber={1} />}
                                    />
                                </Route>
                            </Route>

                            <Route element={<ProtectedRoute />}>
                                <Route path={"/user/logout"} element={<UserLogoutScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute />}>
                                <Route path={"/user/einstellungen"} element={<UserSettingsScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_EXPORTS} />}>
                                <Route path={"/user/export-downloads"} element={<UserDataExportsScreen />} />
                                <Route path={"/user/export-downloads/download/:id"} element={<UserDataExportsDownloadScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_MANAGE_LOGINS} />}>
                                <Route path={"/admin/login"} element={<AdminUserOverviewScreen />} />
                                <Route path={"/admin/login/edit/:userId?"} element={<AdminLoginEditScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_MANAGE_GROUPS} />}>
                                <Route path={"/admin/group"} element={<AdminGroupOverviewScreen />} />
                                <Route path={"/admin/group/edit/:groupId?"} element={<AdminGroupEditScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_MANAGE_VIEW_GROUPS} />}>
                                <Route path={"/admin/view-group"} element={<AdminViewGroupOverviewScreen />} />
                                <Route path={"/admin/view-group/edit/:groupId?"} element={<AdminViewGroupEditScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_PLAUSI_CHECK_CHARGELOGS} />}>
                                <Route path={"/admin/lv-plausi-checks"} element={<AdminPlausiChecksChargelogsOverviewScreen />} />
                                <Route
                                    path={"/admin/lv-plausi-checks/:plausiCheckId?"}
                                    element={<AdminPlausiChecksChargelogsDetailScreen />}
                                />
                            </Route>

                            <Route element={<ProtectedRoute requireOnePermission={VIEW_PERMISSION_ADMIN_MANAGE_VEHICLE_DATA} />}>
                                <Route path={"/admin/fahrzeugdaten-verwalten"} element={<AdminManageVehicleData />} />
                                <Route
                                    path={"/admin/fahrzeugdaten-verwalten/edit/:vehicleId?"}
                                    element={<AdminManageVehicleDataDetails />}
                                />
                            </Route>
                            {/* TODO: hier die neue Berechtigung verwenden */}
                            <Route element={<ProtectedRoute requireAllPermissions={[VIEW_PERMISSION_OCPP_SETTINGS_EDIT]} />}>
                                <Route path={"/admin/ocpp-vorlagen"} element={<AdminOcppTemplateScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireAllPermissions={[VIEW_PERMISSION_OCPP_SETTINGS_EDIT]} />}>
                                <Route path={"/admin/ocpp-vorlagen-details/:templateId?"} element={<AdminOcppTemplateDetailsScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireAllPermissions={[VIEW_PERMISSION_OCPP_SETTINGS_EDIT]}/>}>
                                <Route path={"/admin/ocpp-vorlagen-details/copy/:templateId?"} element={<AdminOcppTemplateDetailsScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute requireAllPermissions={[VIEW_PERMISSION_PHONE_CALL]}/>}>
                                <Route path={"admin/anrufe"} element={<AdminPhoneCallScreen />} />
                                <Route path={"admin/anrufe/edit/:phoneCallId?"} element={<AdminPhoneCallEditScreen />} />
                            </Route>

                            <Route element={<ProtectedRoute />}>
                                <Route path={"/benachrichtigungen"} element={<NotificationCenterScreen />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route element={<LayoutLoggedOutComponent />}>
                        <Route path={getLoginUrl()} element={<UserLoginScreen />} />
                        <Route path={"/kennwort-vergessen"} element={<UserForgotPasswordEmailScreen />} />
                        <Route path={"/kennwort-vergessen/:token"} element={<UserResetPasswordScreen />} />
                        <Route path={"/403"} element={<PageForbiddenScreen />} />
                        <Route path={"*"} element={<PageNotFoundScreen />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default RoutesConfig;
